import React from 'react'
import { ThreeCircles, ThreeDots } from 'react-loader-spinner'

const Loader = () => {
  return (
    
    <div className="flex flex-col items-center justify-center h-screen text-center bg-gray-900">
      <ThreeDots
        visible={true}
        height="80"
        width="80"
        color="#4fa94d"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <p className="mt-4 text-lg text-gray-400">Please wait, content is loading...</p>
    </div>
  )
}

export default Loader